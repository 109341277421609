@import "../../../../Style/variables";

.library {
  width: 100%;

  img {
    cursor: pointer;
  }

  .item-right {
    display: flex;
    flex-direction: row;
    gap: 5px;

    button:disabled,
    button[disabled] {
      border: 1px solid $light-gray;
      background-color: $light-gray;
    }
  }

  .library-header {
    padding: 20px 20px 10px 20px;

    .search-container {
      margin: 30px 0;
      display: flex;
      align-items: center;
      gap: 20px;

      .search {
        width: 432px;
        height: 52px;
        background: $strong-white;
        border: 1px solid $rgba-black;
        ;
        display: flex;
        align-items: center;

        img {
          margin: 0 15px;
        }

        input {
          margin-right: 20px;
          width: 100%;
          border: none;
          background-color: transparent;
          outline: 0;
        }
      }
    }

    .new-exercise-container {
      .primary-button {
        background-color: #553872;
        border-color: #553872;
        font-weight: 600;
      }
    }
  }

  .MuiAccordion-root {
    margin-bottom: 25px;
    margin: 0 15px 25px 15px;
  }

  .filtered-exercises {
    padding: 20px 30px;
  }

  .library-item {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .item-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    h4 {
      font-weight: 700;
      font-size: 14px;
      color: $blue;
      word-break: break-word;
    }

    button {
      width: 24px;
      height: 24px;
      background-color: $blue;
      border: none;
      cursor: pointer;
      ;
      color: $white;
      font-size: 25px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1px;
    }

    .item-left {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .register-exercise-btn {
    width: 35px;
    height: 35px;
    float: left;
    margin: 25px;
  }
}