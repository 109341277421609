@import '../../Style/variables';

.primary-button {
  color: $white;
  background-color: $btn-dark-black;
  border-color: $btn-dark-black;
  font-size: 13px;
  cursor: pointer;
  ;
  height: 52px;
  width: 224px;

  &.disabled {
    opacity: 0.1;
    color: rgba(255, 255, 255, 0.9);
    cursor: default !important;
  }
}
