@import '../../../../../Style/variables';


.form-add-schedule {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;

  @media (max-width: 600px) {
    width: 95%;
    padding: 15px;
    margin: 0;
  }

  .titles {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    .div-image {
      width: 40px;
      height: 40px;
      background: rgba(102, 45, 145, 0.1);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
    }

    > div {
      display: flex;
      align-items: flex-start;

      .div-title {
        h3 {
          margin: 0;
          font-size: 18px;
          
          @media (max-width: 600px) {
            font-size: 16px;
          }
        }

        p {
          margin: 5px 0 0;
          font-size: 14px;
          color: #666;
          
          @media (max-width: 600px) {
            font-size: 12px;
          }
        }
      }
    }

    img {
      cursor: pointer;
    }
  }

  .new-quiz {
    margin: 15px 0;

    .input, .textarea {
      margin-top: 10px;
    }
  }

  // Ajustes para os campos de data
  .MuiFormControl-root {
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  // Ajuste para o layout dos campos de data em mobile
  .MuiBox-root {
    @media (max-width: 600px) {
      flex-direction: column !important;
      gap: 15px !important;
    }
  }

  // Ajuste para o botão em mobile
  .MuiButton-root {
    @media (max-width: 600px) {
      width: 100%;
      margin-top: 10px;
    }
  }

  // Estilização da scrollbar
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}