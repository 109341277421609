@import "../../Style/variables";

.page-wrapper {
  .container {
    display: grid;
    grid-template-columns: minmax(96px, 7%) 64% 29%;
    height: 100%;
    min-height: 100vh;

    .content {
      background-color: #F9FAFB !important;
    }

    .side-menu-container {
      background-color: #000;
      height: inherit;
    }

    @media (max-width: 450px) {
      display: block;
      grid-template-columns: 100%;
      width: 100%;

      .side-menu-container {
        background-color: $primary-color;
      }
    }
  }
}
