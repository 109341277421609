@import "../../Style/variables";

.header-container {
  .return {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 15px;

    span {
      font-weight: 500;
      font-size: 12px;
      text-decoration-line: underline;
      color: $blue;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .container-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 1px;

        strong {
          font-weight: 700;
          font-size: 13px;
          color: $strong-gray;
        }

        span {
          font-weight: 700;
          font-size: 13px;
          color: $rgba-gray;
        }
      }
    }
  }
}
