@import '../../../../Style/variables';


.form-withdraw {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  transition: all ease 0.5s;
  width: 100%;
  max-width: 400px;
  background: $white;
  border: 1px solid $rgba-black;
  ;
  z-index: 10;

  > div {
    padding: 20px;
  }

  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $rgba-black;

    > div {
      display: flex;
      align-items: center;
      gap: 15px;

      .div-image {
        width: 40px;
        height: 40px;
        background: rgba(30, 48, 110, 0.1);
        ;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    > img {
      cursor: pointer;
    }

    .div-title {
      > p {
        font-weight: 500;
        font-size: 12px;
        color: rgba(78, 75, 89, 0.8);
      }
    }
  }

  .new-quiz {
    padding: 15px 20px 0;

    .select,
    .textarea,
    .input {
      margin-top: 11px;
    }
  }
}