@use "../../Style/variables" as *;

.students {
  .container {
    grid-template-columns: minmax(96px, 7%) 1fr;

    .content {
      padding: 15px;

    }
  }
}