@import '../../Style/variables';

.questionnaire-answers {
  .container {
    grid-template-columns: minmax(96px, 7%) 1fr;
    height: 100%;
    background-color: #F9FAFB !important;

    .content {
      background-color: $primary-color;
      padding: 20px 30px;

      .return {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        span {
          font-weight: 500;
          font-size: 12px;
          text-decoration-line: underline;
          color: $blue;
        }
      }

      header {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .infos {
          display: flex;
          align-items: center;
          gap: 30px;

          .divider {
            width: 1px;
            height: 36px;
            background-color: $gray-e0;
          }

          .questions {
            p {
              font-weight: 700;
              font-size: 13px;
              color: $blue;
            }

            span {
              font-weight: 600;
              font-size: 13px;
              color: $gray-secondary;
              opacity: 0.9;
            }
          }
        }

        .student {
          p {
            font-weight: 500;
            font-size: 12px;
            color: $rgba-gray;
            margin-bottom: 5px;
          }

          div {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              width: 32px;
              height: 32px;
            }

            span {
              font-weight: 700;
              font-size: 13px;
              color: $gray-blue;
            }
          }
        }
      }

      .cards-answers {
        display: grid;
        grid-template-columns: repeat(3, minmax(380px, 1fr));
        gap: 25px;

        &.limit-column {
          grid-template-columns: repeat(2, minmax(380px, 1fr));
        }

        @media (max-width: 1360px) {
          grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
        }

        @media (max-width: 1160px) {
          &.limit-column {
            grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
          }
        }
      }
    }
  }
}