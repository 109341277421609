@import "../../Style/variables";


.home {
  .page-wrapper {
    .content {
      background-color: $primary-color;
      padding: 15px;
      height: 100%;
    }
  }
}