@import "../../Style/variables";

.calories-calculator {
  padding: 20px 30px;

  @media (max-width: 768px) {
    padding: 5px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div + div {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 32px;
      }

      span {
        font-weight: 700;
        font-size: 13px;
        color: #4e4b59;
      }
    }
  }

  .divider {
    margin: 20px 0;
    height: 1px;
    background-color: $rgba-black;
  }
}