@import '../../../../Style/variables';

.modal-container {

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    transition: all ease 0.5s;
    width: 100%;
    max-width: 400px;
    background: $white;
    border: 1px solid $rgba-black;
    ;
    z-index: 10;

    > div {
      padding: 20px;
    }

    .titles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $rgba-black;

      > div {
        display: flex;
        align-items: center;
        gap: 15px;

        .div-image {
          width: 40px;
          height: 40px;
          background: $rgba-strong-blue;
          ;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      > img {
        cursor: pointer;
      }
    }

    .info-student {
      display: flex;
      align-items: center;
      gap: 15px;
      border-bottom: 1px solid $rgba-black;

      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }

      .div-title {
        > p {
          font-weight: 700;
          font-size: 13px;
          color: $gray-blue;
        }
      }
    }

    .quiz {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $rgba-black;

      .quiz-infos {
        .shipping {
          > p {
            font-weight: 700;
            font-size: 13px;
            color: $gray-blue;
          }
        }

        > div + div {
          margin-top: 10px;
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;

          span {
            font-weight: 500;
            font-size: 12px;
            color: $blue;
            text-decoration: underline;
          }
        }
      }

      .check {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          font-weight: 500;
          font-size: 12px;
          color: $green;
        }
      }

      .not-check {
        display: flex;
        align-items: center;
        gap: 10px;

        span {
          font-weight: 500;
          font-size: 12px;
          color: $strong-pink;
        }
      }
    }

    .new-quiz {
      padding: 25px 20px;
      border-bottom: 1px solid $rgba-black;

      .div-title {
        > p {
          font-weight: 700;
          font-size: 13px;
          color: $gray-blue;
        }
      }

      .select {
        margin-top: 20px;
      }
    }

    .button {
      display: flex;
      justify-content: flex-end;
      background: #F9F8F8;
      padding: 12px;

      button {
        width: 145px;
      }
    }
  }
}