@import '../../Style/variables';

.react-calendar {

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: center;

    .react-calendar__navigation__label {
      border: none;
      background-color: transparent;
      font-weight: 700;
      font-size: 14px;
      color: $gray-blue;
      text-align: center;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .react-calendar__navigation__arrow {
      width: 32px;
      height: 32px;
      background: $primary-color;
      border: none;
      cursor: pointer;
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
  }

  .react-calendar__viewContainer {
    margin-top: 10px;

    .react-calendar__month-view__weekdays {
      margin-bottom: 10px;
      text-align: center;

      div {
        font-weight: 500;
        font-size: 12px;
        color: $strong-gray;

        abbr[title] {
          text-decoration: none;
        }

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    .react-calendar__month-view__days {
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      justify-items: center;
      align-items: center;

      button {
        border: none;
        background-color: transparent;
        font-weight: 500;
        font-size: 12px;
        color: $strong-gray;
        height: 32px;
        width: 100%;
        max-width: 32px;
        cursor: pointer;
      }

      .react-calendar__tile--active {
        background: $strong-pink;
        border-radius: 122px;
        color: $white;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }

  &.background-blue {
    .react-calendar__viewContainer {
      .react-calendar__month-view__days {
        .react-calendar__tile--active {
          background: $blue;
        }
      }
    }
  }

  .react-calendar__year-view__months,
  .react-calendar__decade-view__years,
  .react-calendar__century-view__decades {
    border-top: 1px solid #f00;

    button {
      background-color: white;
      border: 1px solid rgba(55, 55, 57, 0.1);
      padding: 10px 0;
    }
  }
}