@import '../../../../Style/variables';

.card-answers {
  background: $white;
  border: 1px dashed $rgba-black;
  ;
  width: 90%;

  .title {
    display: flex;
    align-items: center;
    gap: 15px;

    img {
      width: 24px;
      height: 24px;
    }

    > div {
      p {
        font-weight: 700;
        font-size: 13px;
        color: $blue;
      }

      span {
        font-weight: 600;
        font-size: 12px;
        color: $gray-secondary;
        opacity: 0.9;
      }
    }
  }

  .description {
    padding-bottom: 20px;
    border-bottom: 1px solid $rgba-strong-gray;

    p {
      font-weight: 600;
      font-size: 13px;
      color: $rgba-medium-gray;
      margin-bottom: 10px;
    }

    input {
      width: 100%;
      height: 58px;
      background: $strong-white;
      border: 1px solid $rgba-black;
      ;
      padding: 0 15px;
      outline: none;
    }
  }

  .answer {
    padding: 20px 12px;

    h4 {
      font-weight: 700;
      font-size: 13px;
      color: $blue;
      margin-bottom: 5px;
    }

    h6 {
      font-weight: 600;
      font-size: 13px;
      color: $strong-gray;
      opacity: 0.9;
    }
  }

  > div {
    padding: 12px;
  }
}