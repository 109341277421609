@use "sass:map";

$dark-theme: false !default;
$white: #ffffff !default;
$white-fa: #fafafa !default;
$primary-color: #f3f3f5 !default;
$secondary-color: #f5f5f7 !default;
$strong-white: #fcfbfc !default;
$light-gray: #e1e1e1 !default;
$medium-gray: #bcbdc8 !default;
$medium-strong-gray: #bababa !default;
$gray: #c4c4c4 !default;
$gray-ddd: #dddce3 !default;
$gray-d6: #d6d8dc !default;
$gray-e0: #e0e0e0 !default;
$light-gray-10: #3737391a !default;
$light-gray-60: #4e4b5999 !default;
$strong-gray-80: #3d3b46cc !default;
$strong-gray: #3d3b46 !default;
$gray-secondary: #7a7786 !default;
$rgba-gray: rgba(78, 75, 89, 0.6) !default;
$rgba-gray-80: rgba(80, 79, 88, 0.35) !default;
$rgba-medium-gray: rgba(61, 59, 70, 0.75) !default;
$rgba-strong-gray: rgba(55, 55, 57, 0.1) !default;
$gray-blue: #4e4b59 !default;
$gray-light-blue: #5f5c6b !default;
$blue: #1e306e !default;
$light-blue-30: #1e306e4d !default;
$rgba-strong-blue: rgba(30, 48, 110, 0.3) !default;
$rgba-strong-green: rgba(58, 195, 156, 0.1) !default;
$rgba-strong-red: rgba(219, 61, 96, 0.1) !default;
$secondary-pink: #db3d60 !default;
$strong-pink: #c7427b !default;
$error-pink: #f9ecf1 !default;
$green: #3ac49c !default;
$rgba-black: rgba(0, 0, 0, 0.1) !default;
$green-success: #d8f1ea !default;
$blue-progress: #e8eaf0 !default;
$ligth-yellow: #ebf2d0 !default;
$yellow: #c2c627 !default;

$font-manrope: "Manrope";
$font-family: 'Open Sans' !default;


$btn-dark-pink: #c7427b !default;
$btn-light-pink: #db3d60 !default;
$btn-dark-black: #000 !default;


@if $dark-theme {
  $primary-color: darken($primary-color, 60%);
}

$theme-colors: (
        "success": #28a745,
        "info": #17a2b8,
        "warning": #ffc107,
);

@mixin font-bold {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}

@mixin font {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

