@use "../../../../Style/variables" as * ;

.modal-create-meal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-add {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    outline: none;

    .titles {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 24px;

      > div {
        display: flex;
        gap: 16px;
        align-items: center;

        .div-image {
          background: #F5F5F5;
          border-radius: 8px;
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 24px;
            height: 24px;
          }
        }

        .div-title {
          h2 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
          }

          p {
            margin: 4px 0 0;
            color: #666;
            font-size: 14px;
          }
        }
      }

      img[alt="icon-close"] {
        cursor: pointer;
        width: 24px;
        height: 24px;
        padding: 4px;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .disclaimers {
      margin-bottom: 24px;
      
      .MuiAlert-root {
        .MuiAlert-icon {
          align-items: center;
        }
        
        .MuiAlertTitle-root {
          margin: 0;
          font-weight: 600;
        }
      }
    }

    .row {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;

      .new-quiz {
        flex: 1;

        &.full-width {
          width: 100%;
        }

        .input, .select {
          width: 100%;
        }
      }
    }

    .button {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: 768px) {
      width: 95%;
      padding: 16px;

      .row {
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}