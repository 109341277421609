@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');
@import "./Style/variables";


#root {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family !important;
}

.MuiTypography-h3 {
  font-size: 18px !important;
  font-weight: 700;
}

.MuiTypography-subtitle2 {
  font-size: 12px;
  font-weight: 500;
  color: $light-gray-60;
}

.error {
  color: $secondary-pink;
  margin-top: 5px;
  text-align: right;
}

.react-images__pager {
  .react-images__frame {
    @media (max-width: 1024px) {
      min-width: 600px;
      .react-images__view-image {
        width: 100%;
        max-width: 50%;
        height: 100%;
      }
    }
  }
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #f1f2f4;
}

body::-webkit-scrollbar-thumb {
  background-color: $light-gray;
  border: 3px solid $light-gray;
}