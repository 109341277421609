@import "../../Style/variables";

.evolution {
  padding: 20px 30px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div + div {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 32px;
      }

      span {
        font-weight: 700;
        font-size: 13px;
        color: #4e4b59;
      }
    }
  }

  .divider {
    margin: 20px 0;
    height: 1px;
    background-color: $rgba-black;
  }

  .section-calendar {
    margin: 30px 0;
  }

  .info-days {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 15px;

    > div {
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }

    span {
      font-weight: 700;
      font-size: 13px;
    }

    &.blue {
      > div {
        background: $blue;
      }

      span {
        color: $blue;
      }
    }

    &.green {
      > div {
        background: $green;
      }

      span {
        color: $green;
      }
    }
  }

  .description {
    p {
      font-weight: 500;
      font-size: 12px;
      color: rgba(78, 75, 89, 0.7);
    }
  }

  > h3 {
    font-weight: 700;
    font-size: 14px;
    color: #4e4b59;
  }

  .slider-container {
    cursor: pointer;

    .image-carousel {
    }
  }
}

