@import '../../../../Style/variables';

.modal-plans {
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-add-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-add {
      background: #FFFFFF;
      border-radius: 8px;
      padding: 24px;
      width: 90%;
      max-width: 600px;
      max-height: 90vh;
      overflow-y: auto;

      .titles {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 24px;

        > div {
          display: flex;
          gap: 16px;
          align-items: center;

          .div-image {
            background: #F5F5F5;
            border-radius: 8px;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 24px;
              height: 24px;
            }
          }

          .div-title {
            h2 {
              margin: 0;
              font-size: 20px;
              font-weight: 600;
            }

            p {
              margin: 4px 0 0;
              color: #666;
              font-size: 14px;
            }
          }
        }

        img[alt="icon-close"] {
          cursor: pointer;
          width: 24px;
          height: 24px;
          padding: 4px;
          transition: opacity 0.2s;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .new-quiz {
        margin-bottom: 24px;

        .input {
          width: 100%;
        }
      }

      .MuiBox-root {
        button {
          width: auto;
          min-width: 150px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .modal-add-container {
      .modal-add {
        width: 95%;
        padding: 16px;
      }
    }
  }
}