@import "../../Style/variables";

.new-student-form {
  background-color: #F9FAFB;
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 100%;

  .step-label {
    color: $white;
    font-weight: 500;
  }

  .final {
    @media (max-width: 800px) {
      & {
        width: 350px;
        height: 350px;
      }
    }
  }
}