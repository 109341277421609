@import '../../Style/variables';

.modal-delete-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: $rgba-medium-gray;
  z-index: 5;

  .modal-delete {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    transition: all ease 0.5s;
    width: 100%;
    max-width: 400px;
    background: $white;
    border: 1px solid $rgba-black;
    ;
    z-index: 10;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      > span {
        margin-top: 25px;
        width: 40px;
        height: 40px;
        background: rgba(219, 61, 96, 0.1);
        ;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h2 {
        margin-top: 10px;
        font-weight: 700;
        font-size: 18px;
        color: $strong-gray;
        opacity: 0.9;
      }

      p {
        margin-top: 15px;
        font-weight: 500;
        font-size: 14px;
        color: rgba(78, 75, 89, 0.8);
      }

      p + p {
        color: $secondary-pink;
        font-weight: 700;
        margin-top: 3px;
      }

      > div {
        width: 100%;
        margin-top: 25px;
        padding: 12px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        background: #F9F8F8;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        > .cancel-btn {
          cursor: pointer;
          width: 145px;
          height: 52px;
          background: $white;
          border: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: none;
        }

        > .delete-btn {
          cursor: pointer;
          width: 145px;
          height: 52px;
          background: $secondary-pink;
          ;
          border: none;
          color: $white;
        }
      }
    }
  }
}