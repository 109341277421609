.onboarding-wrapper {
  min-height: 100vh;
  background: #f8f9fa;
  padding: 40px 20px;
  
  .onboarding-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    min-height: 600px;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    
    .sidebar {
      width: 320px;
      background: #fff;
      border-right: 1px solid #e9ecef;
      padding: 40px 30px;
    }
    
    .content {
      flex: 1;
      padding: 40px;
      background: #fff;
      
      .step-content {
        .step-title {
          color: #333;
          font-weight: 600;
          margin-bottom: 30px;
          
          &:after {
            content: '';
            display: block;
            width: 80px;
            height: 2px;
            background: #662d91;
            margin-top: 10px;
          }
        }

        .sub-steps {
          margin: 30px 0;
          padding: 20px;
          background: #f8f9fa;
          border-radius: 8px;
          
          .horizontal-stepper {
            .MuiStepLabel-label {
              font-size: 14px;
            }
          }
        }

        .step-component {
          margin-top: 30px;
          min-height: 300px;
        }
      }
      
      .navigation-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        padding-top: 20px;
        border-top: 1px solid #e9ecef;
      }
    }
  }
}

// Responsividade
@media (max-width: 768px) {
  .onboarding-wrapper {
    padding: 20px 10px;
    
    .onboarding-container {
      flex-direction: column;
      
      .sidebar {
        width: 100%;
        padding: 20px;
        border-right: none;
        border-bottom: 1px solid #e9ecef;
      }
      
      .content {
        padding: 20px;
        
        .sub-steps {
          padding: 15px;
          
          .horizontal-stepper {
            .MuiStepLabel-label {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
} 