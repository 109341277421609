@import "../../Style/variables";

.students-details {
  .container {
    grid-template-columns: minmax(96px, 7%) 1fr;
    height: 100%;
    background-color: #F9FAFB !important;

    .content {
      background-color: $primary-color;
      padding: 15px;

      .content-students {

        .Mui-selected {
          background-color: #e1e8ff;
        }

        .students-list {
          table {
            width: 100%;
            border-collapse: collapse;

            tr {
              th {
                font-weight: 600;
                font-size: 12px;
                color: $gray-secondary;
                opacity: 0.9;
                text-align: start;
                padding: 22px 11px;

                img {
                  margin-left: 5px;
                  cursor: pointer;
                }
              }

              td {
                font-weight: 700;
                font-size: 13px;
                color: $gray-blue;
                padding: 11px;

                .student-info {
                  padding: 6px;
                  ;
                  font-weight: 700;
                  font-size: 13px;

                  &.success {
                    background: $green-success;
                    color: $green;
                  }

                  &.progress {
                    background: $blue-progress;
                    color: $blue;
                  }

                  &.error {
                    background: $error-pink;
                    color: $strong-pink;
                  }
                }

                .unread-messages {
                  font-weight: 700;
                  font-size: 13px;
                  text-decoration-line: underline;
                  color: $secondary-pink;
                }

                .infos-container {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  width: 100%;

                  .info-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 34px;
                    width: 150px;
                    ;
                    font-weight: 600;
                    font-size: 13px;
                    color: $white;
                    background-color: $blue;
                    opacity: 0.9;
                    padding: 0 10px;
                    cursor: pointer;

                    &.questionnaire-btn {
                      width: 120px;
                      background-color: $strong-pink;
                    }
                  }
                }

                &:first-child {
                  width: 50px;

                  img {
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                  }
                }

                &:last-child {
                  width: 272px;
                }
              }

              &:nth-last-child(even) {
                background-color: $primary-color;
              }
            }
          }
        }
      }

      .pagination-container {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pagination-btns {
          list-style: none;
          display: flex;
          align-items: center;
          gap: 10px;

          .page-item {
            display: none;
          }

          .previous-btn {
            background: $white;
            border: 1px solid $gray-ddd;
            ;

            .previous-link-btn {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }

          .next-btn {
            background: $white;
            border: 1px solid $gray-ddd;
            ;

            .next-link-btn {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }

          .pagination-disabled {
            background: $primary-color;
          }
        }

        .infos {
          font-weight: 600;
          font-size: 14px;
          color: $gray-blue;
          opacity: 0.4;
        }
      }
    }
  }
}