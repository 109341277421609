@import "../../../../Style/variables";

.chart {
  margin-top: 25px;
  background-color: $white;

  .chart-title {
    padding: 20px;
    border-bottom: 1px solid $rgba-strong-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chart-infos {
      display: flex;
      align-items: center;
      gap: 25px;

      .chart-gap {
        display: flex;
        align-items: center;
        gap: 10px;

        .rectangle-sm {
          width: 12px;
          height: 2px;
          background: $blue;
        }

        .div-rectangle {
          display: flex;
          align-items: center;
          gap: 5px;

          .rectangle-md {
            width: 7px;
            height: 1px;
            background: $rgba-gray-80;
          }
        }

        span {
          font-weight: 400;
          font-size: 13px;
          color: $rgba-gray;
        }
      }

      .chart-select {
        border: 1px solid $rgba-strong-gray;
        ;
        display: flex;
        align-items: center;

        .chart-select-image {
          width: 41px;
          height: 40px;
          background: $white-fa;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid $rgba-strong-gray;
        }

        select {
          border: none;
          border-left: none;
          width: calc(128px - 41px);
          height: 40px;
          background: $white;
          outline: 0;
          padding: 0 5px;
          font-weight: 600;
          font-size: 13px;
          color: $gray-light-blue;

          option {
            font-weight: 600;
            font-size: 13px;
            color: $gray-light-blue;
          }
        }
      }
    }
  }

  .chart-graphic {
    padding: 20px 20px 20px 0;

    .empty-list {
      > p {
        margin-top: 15px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: rgba(78, 75, 89, 0.8);
      }
    }

    .recharts-wrapper {
      .recharts-surface {
        .xAxis {
          font-weight: 600;
          font-size: 12px;
          color: $rgba-gray;
        }

        .yAxis {
          font-weight: 600;
          font-size: 12px;
          color: $rgba-gray;
        }
      }
    }

    .tooltip {
      width: 220px;
      height: 77.38px;
      background: $white;
      ;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;

      div {
        display: flex;
        align-items: center;
        gap: 15px;
        font-weight: 500;
        font-size: 13px;
        color: $rgba-gray;

        span {
          font-weight: 800;
          font-size: 13px;
          color: $strong-gray;
        }
      }
    }
  }
}
