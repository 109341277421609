@import "../../../../Style/variables";

.workout-info {
  .MuiCollapse-root {
    border: 1px solid rgba(55, 55, 57, 0.1);
  }

  .accordion-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;

    @media (max-width: 450px) {
      display: block;
    }

    .accordion-item {
      flex-grow: 1;
      flex-basis: 0;

      .weeks-form-group {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }

      .select,
      .input,
      .textarea {
        margin-top: 10px;
      }

      .dates {
        display: flex;
        align-items: center;
        gap: 15px;
        padding-bottom: 0;

        > div {
          flex-grow: 1;
          flex-basis: 0;
        }

        .datepicker-container {
          margin-top: 10px;
        }
      }

      .div-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
      }
    }

    &.mt {
      margin-top: 12px;
    }
  }
}
