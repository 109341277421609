@import "../../Style/variables";

.manage-client-plan {
  .container {
    grid-template-columns: minmax(96px, 7%) 65% 28%;

    @media (min-width: 100px) and (max-width: 1199px) {
      grid-template-columns: minmax(96px, 7%) 92%;
    }

    .content {
      padding: 20px 30px;

      .new-appointment {
        background-color: $strong-pink;
        border-color: $strong-pink;
        font-weight: 700;
        font-size: 14px;
      }

      .MuiTabPanel-root {
        padding: 0;
      }

      .MuiTabs-indicator {
        display: none;
      }

      .tab-btn {
        width: 120px;
        height: 40px;
        background-color: $white;
        border-color: $rgba-black;
        font-weight: 600;

        &.active {
          color: $white;
          background-color: $blue;
          border-color: $blue;
        }
      }

      .manage-plan-container {
        margin-top: 20px;
        border-left: 1px solid $rgba-black;
        border-right: 1px solid $rgba-black;
        border-bottom: 1px solid $rgba-black;

        .list-item {
          padding: 15px;
          background: $white;
          border-top: 1px solid $rgba-black;

          @media (max-width: 450px) {
            flex-direction: column;
          }

          .item-header {
            @media (max-width: 450px) {
              text-align: center;
            }
          }

          &.background-gray {
            background: $primary-color;
          }

          .actions-btn {
            width: 100%;
            height: 35px;
            background-color: $blue;
            border-color: $blue;
          }
        }

        .new-item-container {
          display: flex;
          align-items: center;
          gap: 15px;

          span {
            font-weight: 600;
            font-size: 12px;
            color: $gray-secondary;
            opacity: 0.9;
          }

          .student-info {
            padding: 6px;
            ;
            font-weight: 700;
            font-size: 13px;

            &.error {
              background: $error-pink;
              color: $strong-pink;
            }
          }
        }

        > div + div {
          .title {
            font-weight: 700;
            font-size: 13px;
            color: $blue;
          }

          .subtitle {
            font-weight: 600;
            font-size: 13px;
            color: $gray-secondary;
            opacity: 0.9;
          }

          .actions {
            display: flex;
            align-items: center;
            gap: 30px;

            .delete {
              cursor: pointer;
            }

            .edit {
              padding: 0 10px;
              height: 40px;
              background: rgba(30, 48, 110, 0.1);
              ;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              cursor: pointer;

              span {
                font-weight: 600;
                font-size: 12px;
                color: $blue;
                opacity: 0.9;
              }
            }
          }
        }
      }
    }
  }
}