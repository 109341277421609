.credit-card-payment {
  #form-checkout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 50%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  input,
  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    ;
    box-sizing: border-box;
  }

  #form-checkout__installments {
    width: 100%;
  }

  input:focus,
  select:focus {
    border-color: #007bff;
    outline: none;
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    ;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  button:disabled {
    background-color: #cccccc;
  }

  .progress-bar {
    width: 100%;
    height: 5px;
    background-color: #007bff;
    margin-top: 20px;
  }


  .container {
    height: 18px;
    display: inline-block;
    border: 1px solid rgb(118, 118, 118);
    ;
    padding: 1px 2px;
  }
}