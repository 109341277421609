@import "../../Style/variables";

.chat-container {
  .container {
    grid-template-columns: minmax(96px, 7%) 1fr;

    .content {
      background-color: $primary-color;
      padding: 20px 30px;

      .cursor-pointer {
        cursor: pointer;
      }

      .return {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        span {
          font-weight: 500;
          font-size: 12px;
          text-decoration-line: underline;
          color: $blue;
        }
      }
    }
  }

}