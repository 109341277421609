@import "../../Style/variables";

.manage-accounts {
  .container {
    grid-template-columns: minmax(96px, 7%) 1fr;

    .content {
      background-color: $primary-color;
      padding: 15px;

      .info {
        display: grid;
        grid-template-columns: 20% 75%;
        gap: 30px;
        margin-top: 40px;

        @media (max-width: 450px) {
          grid-template-columns: 100%;
        }

        .MuiTabPanel-root {
          padding: 0;
          margin-top: 20px;
        }

        .menu-tab {
          font-family: $font-family;
          font-size: 14px;
          line-height: 19.12px;
          border: 1px solid #0000001a;
          background-color: $white;
          color: $light-blue-30;

          &.Mui-selected {
            background-color: $blue;
            color: $white;
          }
        }

        .MuiTabs-indicator {
          background-color: $blue;
        }
      }
    }
  }
}
