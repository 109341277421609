@import "../../../../Style/variables";

.password {
  height: 100%;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    padding-bottom: 0;
    background-color: $white;
    border: 1px solid #0000001a;
    border-bottom: none;
  }

  .password-form {
    background-color: $white;
    border: 1px solid #0000001a;
    padding: 50px;

    form {
      display: flex;
      flex-direction: column;
      gap: 25px;
      background-color: $white;
      align-items: center;

      .input-container {
        width: 350px;

        @media (max-width: 450px) {
          width: 100%;
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
    }
  }
}
