@import "../../../../Style/variables";

.finance {
  height: 100%;
  background-color: $white;
  border: 1px solid #0000001a;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid $light-gray-10;

    .container-left {
      > h3 {
        font-size: 18px;
        font-weight: 700;
      }

      > span {
        font-size: 12px;
        font-weight: 500;
        color: $light-gray-60;
      }
    }

    .container-right {
      max-width: 50%;

      .primary-button {
        max-width: 100%;
        height: 52px;
      }
    }
  }

  .data {
    padding: 30px;
    width: 100%;

    .form {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 30px;

      @media (max-width: 450px) {
        grid-template-columns: 100%;
      }

      .column {
        display: grid;
        row-gap: 25px;
        margin-right: 25px;
      }

      .credential-container {
        display: grid;
        grid-template-columns: 47% 47%;
        gap: 6%;
      }
    }
  }
}
