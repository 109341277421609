@import "../../Style/variables";

.questionnaire {
  .container {
    grid-template-columns: minmax(96px, 7%) 1fr;

    .content {
      background-color: $primary-color;
      padding: 15px;

      .cursor-pointer {
        cursor: pointer;
      }

      .questionnaire-container {
        margin-top: 30px;
        border-left: 1px solid $rgba-black;
        border-right: 1px solid $rgba-black;
        border-bottom: 1px solid $rgba-black;

        > div {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 20px;
          background: $white;
          border-top: 1px solid $rgba-black;

          &.background-gray {
            background: $primary-color;
          }

          .infos {
            width: 270px;

            > div {
              display: flex;
              align-items: center;
              justify-content: space-between;

              span {
                font-weight: 700;
                font-size: 13px;
                color: $blue;
              }

              .span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 8px;
                background: rgba(30, 48, 110, 0.1);
                ;
                font-weight: 600;
                font-size: 12px;
                color: $blue;
                opacity: 0.9;
              }

              > div {
                margin-top: 10px;

                p {
                  font-weight: 600;
                  font-size: 12px;
                  color: #7a7786;
                  opacity: 0.9;
                }

                p + p {
                  font-weight: 700;
                  font-size: 13px;
                  color: $blue;
                }
              }
            }
          }
        }

        > div + div {
          .title {
            font-weight: 700;
            font-size: 13px;
            color: $blue;
          }

          .subtitle {
            font-weight: 600;
            font-size: 13px;
            color: $gray-secondary;
            opacity: 0.9;
          }

          .actions {
            display: flex;
            align-items: center;
            gap: 30px;

            .delete {
              cursor: pointer;
            }

            .edit {
              padding: 0 10px;
              height: 40px;
              background: rgba(30, 48, 110, 0.1);
              ;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              cursor: pointer;

              span {
                font-weight: 600;
                font-size: 12px;
                color: $blue;
                opacity: 0.9;
              }
            }
          }
        }

        .empty-list {
          border-top: 1px solid $rgba-black;
          background-color: $white;
          height: calc(513px - 93px);
          display: flex;
          align-items: center;
          justify-content: center;

          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            .images-container {
              display: flex;
              flex-direction: column;
              align-items: center;

              .image-top {
                margin-left: -15px;
              }

              .image-quite {
                margin: -60px 0 -60px 40px;
                z-index: 1;
              }
            }

            h4 {
              margin-top: 10px;
              font-weight: 700;
              font-size: 15px;
              color: rgba(78, 75, 89, 0.9);
            }

            p {
              text-align: center;
              font-weight: 500;
              font-size: 13px;
              color: rgba(78, 75, 89, 0.8);
            }
          }
        }
      }
    }
  }
}
