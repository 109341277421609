@import '../../Style/variables';

.sign-in {
  .logo {
    @media (max-width: 450px) {
      width: 100%;
    }
  }

  .sign-in-title {
    @media (min-width: 650px) {
      margin: 50px 0px 0px;
      font-size: 2.75rem;
    }

    @media (max-width: 450px) {
      margin: 50px 0px 0px;
      font-size: 2.00rem;
    }
  }
}