@import '../../Style/variables';

.secondary-button {
  color: $white;
  background-color: $blue;
  border-color: $blue;
  font-size: 13px;
  cursor: pointer;
  ;
  height: 52px;
  width: 224px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.disabled {
  opacity: 0.1;
  color: rgba(255, 255, 255, 0.9);
}