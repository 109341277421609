@import '../../../../Style/variables';

.people {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $secondary-color;
  border-radius: 46px;
  padding: 7px 14px;
  position: relative;
  cursor: pointer;

  &.past {
    background: $light-gray;
  }

  .people-left {
    display: flex;
    align-items: center;
    gap: 10px;

    > div {
      display: flex;
      flex-direction: column;

      > p {
        font-weight: 600;
        font-size: 12px;
        color: $strong-pink;
      }

      strong {
        font-weight: 700;
        font-size: 12px;
        color: $strong-gray;
      }

      span {
        font-weight: 500;
        font-size: 12px;
        color: $rgba-medium-gray;
      }
    }
  }

  &::before {
    content: url('../../../../../public/Assets/Images/icon-arrow-bottom-pink.svg');
    position: absolute;
    top: -12px;
    left: 38px;
    width: 5px;
    height: 20px;
  }
}