@import "../../Style/variables";

.diet-details {
  .container {
    grid-template-columns: minmax(96px, 7%) 65% 28%;
  }

  .content {
    background-color: $primary-color;
    padding: 20px 30px;
  }
}
