@import "../../Style/variables";

.wallet {
  .container {
    grid-template-columns: minmax(96px, 7%) 1fr;

    .content {
      background-color: $primary-color;
      padding: 15px;
    }
  }
}