@use "../../../../../../Style/variables" as * ;

.form-meals {
  background: $white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  > div {
    padding: 20px;
  }

  > .item-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .exercise-item-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media (max-width: 450px) {
      grid-template-columns: 100%;
    }

    .exercise-item {
      background: #fcfbfc;
      border: 1px dashed rgba(0, 0, 0, 0.1);
      ;

      > div {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .cursor-pointer {
        cursor: pointer;
      }

      .exercise-title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        > div {
          display: flex;
          align-items: center;
          gap: 13px;
        }
      }

      .p-title {
        font-weight: 700;
        font-size: 13px;
        color: $blue;
      }

      .not-spare-exercise {
        font-weight: 500;
        font-size: 12px;
        color: $secondary-pink;
        margin: 2px 0 10px;
      }

      .exercise-infos {
        .left {
          margin-left: 32px;
        }

        > div + div {
          display: flex;
          gap: 13px;
        }

        .add-spare-exercise {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
        }
      }
    }
  }

  .empty-list {
    background-color: $white;
    height: calc(513px - 93px);
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .images-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image-top {
          margin-left: -15px;
        }

        .image-quite {
          margin: -60px 0 -60px 40px;
          z-index: 1;
        }
      }

      h4 {
        margin-top: 10px;
        font-weight: 700;
        font-size: 15px;
        color: rgba(78, 75, 89, 0.9);
      }

      p {
        text-align: center;
        font-weight: 500;
        font-size: 13px;
        color: rgba(78, 75, 89, 0.8);
      }
    }
  }
}
