@import "../../Style/variables";

.workout-details {
  .container {
    grid-template-columns: minmax(96px, 7%) 69% 23%;

    @media (min-width: 856px) and (max-width: 1400px) {
      grid-template-columns: minmax(96px, 7%) 60% 30%;
    }
    @media (min-width: 760px) and (max-width: 855px) {
      grid-template-columns: minmax(96px, 7%) 55% 35%;
    }
  }

  .content {
    background-color: $primary-color;
    padding: 20px 30px;

    .stack-container {
      margin-top: 35px;

    }

    .preview-questionnaire {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      span {
        font-weight: 500;
        font-size: 12px;
        color: $blue;
        text-decoration: underline;
      }
    }
  }
}