@import "../../../../Style/variables";

.plan-form {
  padding: 24px;

  .data {
    max-width: 1200px;
    margin: 0 auto;

    .plan-header {
      margin-bottom: 24px;

      h3 {
        font-size: 24px;
        font-weight: 600;
        color: #1a1a1a;
      }
    }

    .form {
      .price-container {
        position: relative;
        
        input {
          width: 100%;
          padding: 16.5px 14px;
          border: 1px solid rgba(0, 0, 0, 0.23);
          border-radius: 4px;
          font-size: 16px;
          transition: all 0.2s;

          &:hover {
            border-color: rgba(0, 0, 0, 0.87);
          }

          &:focus {
            border-color: #1976d2;
            border-width: 2px;
            outline: none;
          }
        }

        fieldset {
          position: absolute;
          top: -8px;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0;
          padding: 0 8px;
          pointer-events: none;
          border-radius: inherit;
          border-style: solid;
          border-width: 1px;
          overflow: hidden;
          min-width: 0%;
          border-color: rgba(0, 0, 0, 0.23);

          legend {
            float: unset;
            width: auto;
            height: 11px;
            display: block;
            padding: 0;
            font-size: 0.75em;
            max-width: 100%;
            white-space: nowrap;
            span {
              padding: 0 5px;
              color: rgba(0, 0, 0, 0.6);
              background: #fff;
            }
          }
        }
      }
    }
  }
}
