@import "../../Style/variables";

.side-menu {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .menu-mobile {
    display: none;

    .mobile-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

  }

  .menu-desktop {
    display: block;
  }

  @media (max-width: 450px) {
    & {
      display: block;
    }

    .menu-mobile {
      display: block;
      padding: 0;

      .mobile-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .invite {
      display: none !important;
    }

    .menu-desktop {
      display: none;
    }
  }
}

.menu-container {

  .logo {
    display: flex;
    width: 90%;
    color: $gray;
  }

  .menu-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 84px;
    cursor: pointer;
    color: $gray;

    @media (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20%;
    }

    h4 {
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      color: $gray;
    }

    svg {
      height: 1.3em;
    }

    .menu-image {
      width: 24px;
      height: 24px;
    }

    .logo {
      width: 55px;
      height: 70px;
    }

    &.active {
      background-color: $strong-pink;
      color: white;

      h4 {
        color: white;
      }

      &::before {
        content: url("../../../public/Assets/Images/icon-arrow.svg");
        position: absolute;
        display: flex;
        align-items: center;
        left: 0;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.close-drawer-btn {
  display: flex;
  align-self: flex-end;
}