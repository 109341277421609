@import '../../../../Style/variables';

.calendar {
  padding: 2.2rem;

  .calendar-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
      display: flex;
      flex-direction: column;
    }

    .title-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .circle {
        position: absolute;
        top: -4px;
        right: -3px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 2px;
        width: 20px;
        height: 20px;
        background: $strong-pink;
        border-radius: 93px;

        span {
          font-weight: 700;
          font-size: 12px;
          color: $white;
        }
      }
    }
  }

  .section-calendar {
    margin-top: 30px;
  }

  .calendar-divider {
    border: 1px solid $rgba-strong-gray;
    border-radius: 12px;
    margin: 30px 0;
  }

  .calendar-commitments {
    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      font-weight: 700;
      font-size: 13px;
      color: $strong-gray;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      color: $rgba-gray;
    }
  }

  .calendar-people {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    > p {
      font-weight: 500;
      font-size: 16px;
      color: $rgba-gray;
    }
  }
}