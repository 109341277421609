@import "../../Style/variables";

.edit-questionnaire {
  .container {
    grid-template-columns: minmax(96px, 7%) 1fr;

    .content {
      background-color: $primary-color;
      padding: 20px 30px;

      .cursor-pointer {
        cursor: pointer;
      }

      .return {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        span {
          font-weight: 500;
          font-size: 12px;
          text-decoration-line: underline;
          color: $blue;
        }
      }

      .section-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        @media (max-width: 450px) {
          display: block;
        }

        > div {
          display: flex;
          align-items: center;
          gap: 25px;

          .light-blue {
            font-weight: 500;
            font-size: 12px;
            text-decoration-line: underline;
            color: $blue;
          }
        }

        .divider {
          width: 2px;
          height: 36px;
          background-color: $gray-e0;
        }
      }

      .title-blue {
        font-weight: 700;
        font-size: 13px;
        color: $blue;
      }

      .cards-quiz {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        margin-top: 30px;

        @media (max-width: 450px) {
          grid-template-columns: 100%;
        }

        .card-quiz {
          background-color: $white;
          border: 1px dashed rgba(0, 0, 0, 0.1);
          ;

          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
              display: flex;
              align-items: center;
              gap: 15px;

              img {
                width: 24px;
                height: 24px;
              }

              > div {
                p {
                  font-weight: 700;
                  font-size: 13px;
                  color: $blue;
                }

                span {
                  font-weight: 600;
                  font-size: 12px;
                  color: $gray-secondary;
                  opacity: 0.9;
                }
              }

              .button-save {
                margin-left: 10px;
                width: 145px;
                height: 52px;
                background: rgba(30, 48, 110, 0.1);
                ;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                outline: 0;
                border: 0;
                font-weight: 700;
                font-size: 13px;
                color: $blue;
                cursor: pointer;
              }
            }
          }

          .description {
            padding-bottom: 20px;
            border-bottom: 1px solid $rgba-strong-gray;

            p {
              font-weight: 600;
              font-size: 13px;
              color: $rgba-medium-gray;
              margin-bottom: 10px;
            }

            input {
              width: 100%;
              height: 58px;
              background: $strong-white;
              border: 1px solid $rgba-black;
              ;
              padding: 0 15px;
              outline: none;
              font-weight: 600;
              font-size: 13px;
              color: rgba(78, 75, 89, 0.9);
            }
          }

          > div {
            padding: 12px;
          }

          .add {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            cursor: pointer;
            height: 52px;
          }

          &.mh {
            max-height: 52px;
          }
        }
      }

      .div-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;
      }

      .text-button-save {
        text-align: end;
        font-weight: 500;
        font-size: 12px;
        color: rgba(78, 75, 89, 0.6);
        margin-top: 7px;

        &.disabled {
          display: none;
        }
      }
    }
  }
}
