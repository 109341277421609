@import "../../../../../../Style/variables";

.exercise-box {
  background: #fcfbfc;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  ;

  img {
    cursor: pointer;
  }

  &.background-gray {
    background: $primary-color;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .linked {
    padding-left: 45px;
  }

  .exercise-title {

    > div {
      display: flex;
      align-items: center;
    }
  }

  .p-title {
    font-weight: 700;
    color: $blue;
  }

  .not-spare-exercise {
    font-weight: 500;
    color: $secondary-pink;
    margin: 2px 0 10px;
  }

  .exercise-infos {
    .left {
      margin-left: 45px;
    }

    > div + div {
      display: flex;
      gap: 13px;
    }

    .add-spare-exercise {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 640px) {
  .exercise-box {

    .linked {
      padding-left: 0px;
    }
  }
}