@import "../../Style/variables";

.upgrade {
  width: 100%;

  .container {
    width: 100%;

    .content {
      width: 100%;
      background-color: $primary-color;
      padding: 20px 30px;
      height: 100%;

      .header {
        margin-bottom: 25px;
      }

      .btn-link-marketplace {
        font-size: 12px;
      }

      .new-plan {
        background-color: $white;
        border: 1px solid #0000001a;
        width: 100%;
        padding: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .form-marketplace {
        background-color: $white;
        border: 1px solid #0000001a;
        width: 100%;
        padding: 0 20px;
        margin-top: 25px;

        .btn-link-marketplace {
          text-decoration: none;
          color: $white;
        }

        .primary-button {
          width: 160px;

          .MuiTypography-body2 {
            font-size: 12px;
          }
        }

        .btn-download-manual {
          background-color: #553872;
          border-color: #553872;
          padding: 10px;
        }
      }
    }
  }
}