@import "../../Style/variables";

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(85, 56, 114, 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(85, 56, 114, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(85, 56, 114, 0);
    }
}

.training-list {
    .container {

        grid-template-columns: minmax(96px, 7%) 1fr;

        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }

        .content {
            background-color: $primary-color;
            padding: 20px 30px;
    
            .list-item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                padding: 20px;
                background: #fff;
                border: 1px solid rgba(0, 0, 0, 0.1);
                margin-bottom: 1px;
                margin-top: 15px;

                @media (min-width: 600px) {
                    flex-direction: row;
                    align-items: center;
                }
                
                .secondary-button {
                    margin-left: auto;
                    width: 4rem;
                }
        
                &.background-gray {
                    background: #F9FAFB;
                }
        
                .item-header {
                    .title {
                        font-weight: 700;
                        font-size: 13px;
                        color: #1e306e;
                        width: 100%;
                    }
        
                    .subtitle {
                        font-weight: 600;
                        font-size: 14px;
                        color: #7a7786;
                        opacity: 0.9;
                        width: 100%;
                        display: flex;
                        gap: 8px;
                        flex-wrap: nowrap;
                        overflow-x: auto;
                    }

                    
                }
        
                .actions {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    @media (min-width: 600px) {
                        margin-top: 0;
                    }
        
                    .delete {
                        cursor: pointer;
                    }
        
                    .edit {
                        padding: 0 10px;
                        height: 40px;
                        background: rgba(30, 48, 110, 0.1);
                        ;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        cursor: pointer;
        
                        span {
                            font-weight: 600;
                            font-size: 12px;
                            color: #1e306e;
                            opacity: 0.9;
                        }
                    }
                }
            }
        }
    }
}