@import '../../../../Style/variables';

.card {
  background-color: $white;
  border: 1px solid $rgba-strong-gray;
  ;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .card-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    span {
      font-weight: 600;
      font-size: 13px;
      color: $medium-gray;
    }

    h4 {
      font-weight: 700;
      font-size: 18px;
      color: $strong-gray;
    }

    strong {
      font-weight: 500;
      font-size: 12px;
      color: $rgba-medium-gray;

      &.active {
        color: $green;
      }

      &.disabled {
        opacity: 1;
        color: $secondary-pink;
      }
    }
  }

  .card-right {
    width: 32px;
    height: 32px;
    ;
    display: flex;
    align-items: center;
    justify-content: center;

    &.red {
      background: $rgba-strong-red;
    }

    &.green {
      background: $rgba-strong-green;
    }
  }
}