@import '../../../../Style/variables';

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: $white;
  padding-top: 25px;
  border: 1px solid #0000001A;

  .profile-picture-container {
    display: flex;
    flex-direction: column;

    div {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }


  strong {
    font-weight: 700;
    font-size: 16px;
    color: $strong-gray;
  }

  span {
    font-weight: 700;
    font-size: 12px;
    color: $rgba-gray;
  }

  .revenue-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-10;

    span {
      font-weight: 500;
      font-size: 13px;
      color: $strong-gray-80;
    }

    .value {
      font-weight: 700;
      font-size: 13px;
      color: $strong-pink;

      &.money {
        color: $green;
      }
    }
  }
}