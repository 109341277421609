@import "../../Style/variables";

.student-header {
  .return {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    span {
      font-weight: 500;
      font-size: 12px;
      text-decoration-line: underline;
      color: $blue;
    }
  }

  header {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .student {
      div {
        display: flex;
        align-items: center;
        gap: 15px;

        p {
          font-weight: 500;
          font-size: 12px;
          color: $rgba-gray;
          margin-bottom: 5px;
        }

        img {
          width: 32px;
          height: 32px;
        }

        span {
          font-weight: 700;
          font-size: 13px;
          color: $gray-blue;
        }
      }
    }
  }
}
