@import "../../Style/variables";

.empty-state {
  border-top: 1px solid $rgba-black;
  background-color: $white;
  height: calc(513px - 93px);
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    .images-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .image-top {
        margin-left: -15px;
      }

      .image-quite {
        margin: -60px 0 -60px 40px;
        z-index: 1;
      }
    }

    h4 {
      margin-top: 10px;
      font-weight: 700;
      font-size: 15px;
      color: rgba(78, 75, 89, 0.9);
    }

    p {
      text-align: center;
      font-weight: 500;
      font-size: 13px;
      color: rgba(78, 75, 89, 0.8);
    }
  }
}