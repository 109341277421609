@import '../../../../Style/variables';

.general-info {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  gap: 18px;

  @media (max-width: 450px) {
    overflow-x: scroll;
  }

  .content-cards-subtitle {
    font-weight: 600;
    font-size: 13px;
    color: $medium-gray;
    margin-bottom: 2px;
  }

  .card-left {
    background-color: $white;
    border: 1px solid $rgba-strong-gray;
    ;
    padding: 20px;

    .card-left-content {
      margin-top: 10px;
      display: flex;
      align-items: flex-start;
      gap: 20px;

      .card-left-image {
        width: 32px;
        height: 32px;
        ;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $rgba-strong-green;
      }

      .card-left-block {
        flex-grow: 1;

        > .block-green {
          margin-top: 15px;
          height: 32px;
          ;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $rgba-strong-green;

          span {
            font-weight: 600;
            font-size: 12px;
            color: $green;
          }
        }
      }

      .mt-7 {
        margin-top: 7px;
      }
    }
  }

  .card-right {
    background-color: $white;
    border: 1px solid $rgba-strong-gray;
    ;
    padding: 20px;

    div {
      flex-grow: 1;
    }

    .card-right-students {
      width: 170px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -35px;

      span {
        font-weight: 600;
        font-size: 13px;
        color: $medium-gray;
      }

      strong {
        font-weight: 700;
        font-size: 16px;
        color: $strong-gray;
        opacity: 0.9;
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      .right-container {
        display: grid;
        grid-template-columns: 60px 20px auto;
        align-items: center;

        .right-divider {
          max-width: 3px;
          height: 50px;
          ;
        }

        .right-content {
          .right-content-title {
            font-weight: 700;
            font-size: 13px;
            color: $medium-strong-gray;
          }

          .right-content-subtitle {
            font-weight: 500;
            font-size: 12px;
            color: $rgba-medium-gray;
          }
        }

        span {
          font-weight: 700;
          font-size: 13px;
        }
      }
    }
  }
}