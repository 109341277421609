@import "../../Style/variables";

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(85, 56, 114, 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(85, 56, 114, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(85, 56, 114, 0);
    }
}

.preset {
    .container {
        grid-template-columns: minmax(96px, 7%) 1fr;
        height: 100%;
        background-color: #F9FAFB !important;

        .content {
            background-color: $primary-color;
            padding: 20px 30px;

            .cursor-pointer {
                cursor: pointer;
            }

            .preset-container {
                margin-top: 30px;
                border-left: 1px solid $rgba-black;
                border-right: 1px solid $rgba-black;
                border-bottom: 1px solid $rgba-black;

                > .preset-item {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 20px;
                    background: $white;
                    border-top: 1px solid $rgba-black;

                    &.background-gray {
                        background: $primary-color;
                    }

                    .title {
                        font-weight: 700;
                        font-size: 13px;
                        color: $blue;
                    }

                    .subtitle {
                        font-weight: 600;
                        font-size: 13px;
                        color: $gray-secondary;
                        opacity: 0.9;
                    }

                    .actions {
                        display: flex;
                        align-items: center;

                        .delete {
                            cursor: pointer;
                        }

                        .edit {
                            padding: 0 10px;
                            height: 40px;
                            background: rgba(30, 48, 110, 0.1);
                            ;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 10px;
                            cursor: pointer;

                            span {
                                font-weight: 600;
                                font-size: 12px;
                                color: $blue;
                                opacity: 0.9;
                            }
                        }
                    }
                }

                .empty-list {
                    border-top: 1px solid $rgba-black;
                    background-color: $white;
                    height: calc(513px - 93px);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 15px;

                        .images-container {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .image-top {
                                margin-left: -15px;
                            }

                            .image-quite {
                                margin: -60px 0 -60px 40px;
                                z-index: 1;
                            }
                        }

                        h4 {
                            margin-top: 10px;
                            font-weight: 700;
                            font-size: 15px;
                            color: rgba(78, 75, 89, 0.9);
                        }

                        p {
                            text-align: center;
                            font-weight: 500;
                            font-size: 13px;
                            color: rgba(78, 75, 89, 0.8);
                        }
                    }
                }
            }
        }
    }
}