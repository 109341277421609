@import '../../Style/variables';

.subtitle {
  margin-top: 4px;

  p {
    font-weight: 500;
    font-size: 12px;
    color: $rgba-gray;
  }
}